import {GalleryImage, Scene7Config} from '../typings/gallery';
import {convertCutline} from '../utils/app';
import { AppConstants } from '../utils/app-constants';

export class GalleryFetchError extends Error {
  public constructor(message: string, public readonly url?: string) {
    super(message);
  }
}

export const FOCAL_POINT_IDENTIFIER = '$$FOCAL_POINT$$';

export const getScene7Extension = (scene7Config?: Scene7Config): string | undefined => {
    if (!scene7Config) return;

    // Validate Scene7 Configuration
    let hasScene7Request = scene7Config?.useScene7Src === 'true';
    if (!hasScene7Request) return;

    let scene7extension = `.scene7Src.dir.json/srcSet/${scene7Config?.useImgSrcSet}/width//height//aspectRatio//focalPoint/${FOCAL_POINT_IDENTIFIER}/optimized/true/.cache`;
    if (hasScene7Request && scene7Config?.scene7ImgExtension) {
      scene7extension = scene7Config?.scene7ImgExtension;
    }
    return scene7extension
  }
export async function fetchImageSrc(url: string) {
  const promise = await fetch(url, {});
  const response = await promise?.json();

  if (promise.status >= 500) {
    throw new GalleryFetchError(
      response?.message || 'Error getting image from url:',
      url,
    );
  }
  return response.src || response.srcSet;
}

export async function getImageStructure(
  image: GalleryImage,
  extension: string,
  baseUrl: string = '',
  isRequestScene7: boolean = false,
): Promise<GalleryImage> {
  let imagePath = '';
  let requestUrl = '';
  let disclaimers: string | undefined = undefined;
  let cutline: string | undefined = undefined;
  if (image?.isLoaded) return image;
  try {
    if (isRequestScene7) {
      requestUrl = `${baseUrl}${image.src}${extension}`;
      requestUrl = requestUrl.replace(
        FOCAL_POINT_IDENTIFIER,
        image?.focalPoint && image.focalPoint !== 'NONE'
          ? image.focalPoint
          : '',
      );
      imagePath = await fetchImageSrc(requestUrl);
    } else {
      imagePath = `${baseUrl}${image.src}`;
    }
  } catch (error: any) {
    console.log(`Warning!: Image was not found when requesting src REQUEST: ${baseUrl+image.src} ERROR: ` + error.message);
    imagePath = AppConstants.PlaceHolder960x432
  }

  // Validating Disclaimers
  if (image?.disclaimers) {
    try {
      disclaimers = JSON.parse(image?.disclaimers as string);
    } catch (error) {
      console.log(
        'Warning!: Image disclaimers cannot be parsed',
        error,
        image.src,
      );
    }
  }

  // Validating Cutline
  if (image?.cutline) {
    try {
      cutline = convertCutline(image.cutline);
    } catch (error) {
      console.log('Warning!: Image cutLine cannot be parsed', error, image.src);
    }
  }
  return {
    ...image,
    alt: image?.alt,
    path: imagePath,
    cutline,
    disclaimers,
    isLoaded: imagePath ? true : false
  };
}

import {styled, Breakpoints} from '@vw-marketing/us-components';

export const Container: any = styled.div`
  width: 47.5%;

  @media (max-width: ${Breakpoints.b560}px) {
    width: 90%;
    margin: auto;
  }

  .image-container:hover + .link-container {
    button {
      color: rgb(0, 64, 197);
    }
  }
`;

interface ImageWrapperProps {
  paddingTop?: string;
}

export const ImageWrapper: any = styled.div<ImageWrapperProps>`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  overflow: hidden;
  @media (max-width: ${Breakpoints.b560}px) {
    ${(props: any) =>
      props.paddingTop ? `padding-top: ${props.paddingTop};` : ''}
  }

  img {
    transition: transform 2.2s cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s;
  }
  &:hover {
    img {
      -webkit-transform: scale(1.03);
      transform: scale(1.03);
      cursor: pointer;
    }
  }
`;

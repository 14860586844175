import React, { useEffect } from 'react';
import { LoaderContainer, LoaderSpinner } from '../loader-spinner';
import { GallerySlider } from '../gallery-slider';
import { ModalGalleryContainer } from '../gallery-modal';
import { useTrackingManager } from '../../hooks/use-tracking-manager';
import { SLICK_SETTINGS_1, SLICK_SETTINGS_2 } from './helpers';
import { GallerySection } from '../../app/standalone';
import { onHandleGalleryLayerLoadTrackingEvent, onHandlerGalleryLayerCloseTrackingEvent } from '../../utils/tracking-helpers';
import { GalleryTrackingContent } from '../../typings/gallery';

interface HandleDataLoadProps {
  readonly modalActive: boolean;
  readonly currentGallery: GallerySection;
  readonly setModalActive: any;
  readonly trackingContent?: GalleryTrackingContent
  readonly isWithSrcSet: boolean;
}

export const HandleDataLoad: React.FC<HandleDataLoadProps> = (
  props,
): JSX.Element | null => {
  const {
    modalActive,
    setModalActive,
    currentGallery,
    trackingContent,
    isWithSrcSet
  } = props;
  const trackingManager = useTrackingManager();
 
  useEffect(() => {
    if (modalActive) {
      trackingManager && onHandleGalleryLayerLoadTrackingEvent(
        currentGallery.galleryType || "",
        trackingManager,
        trackingContent,
        currentGallery.images,
        1,
      );
    }
  }, [modalActive]);

  const onCloseHandler = () => {
    setModalActive(false);
    trackingManager && onHandlerGalleryLayerCloseTrackingEvent(trackingManager, trackingContent);
  };
  return (
    <ModalGalleryContainer active={modalActive} onCloseHandler={onCloseHandler}>
        <GallerySlider
        settings1={SLICK_SETTINGS_1}
        settings2={SLICK_SETTINGS_2}
        setModalActive={setModalActive}
        trackingManager={trackingManager}
        trackingContent={trackingContent}
        gallery={currentGallery}
        isWithSrcSet={isWithSrcSet}
         />
      {!currentGallery && (
        <LoaderContainer>
          <LoaderSpinner showSpinner={true} label="Loading Gallery..." />
        </LoaderContainer>
      )}
    </ModalGalleryContainer>
  );
};

import {FeatureAppEnvironment} from '@feature-hub/core';
import React from 'react';
import { FeatureAppConfig, FeatureAppDependencies } from '../typings/fa-environment-config';
import {useSafeContext} from './use-safe-context';

export type GalleryAppEnvironment = FeatureAppEnvironment<
  FeatureAppDependencies,
  FeatureAppConfig
>;

export const FeatureAppEnvironmentContext = React.createContext<
GalleryAppEnvironment | undefined
>(undefined);

export function useFeatureAppEnvironment(): GalleryAppEnvironment {
  return useSafeContext(FeatureAppEnvironmentContext, 'featureAppEnvironment');
}

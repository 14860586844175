import {object, optional, string, array} from 'superstruct';

/**
 * Gallery Image Configuration
 */
export interface GalleryImage {
  src?: string;
  alt?: string;
  cutline?: string;
  disclaimers?: string | any[];
  path?: string;
  focalPoint?: string;
  srcSet?: string;
  isLoaded?: boolean;
}
// SCHEMA DEFINITION
export const galleryImageObject = object({
  src: optional(string()),
  alt: optional(string()),
  cutline: optional(string()),
  disclaimers: optional(string()),
  focalPoint: optional(string()),
});

// SCHEMA DEFINITION
export const galleryConfiguration = object({
  showCtaLabel: optional(string()),
  galleryType: optional(string()),
  hideCTA: optional(string()),
  imageCtaLabel: optional(string()),
  imageCtaUrl: optional(string()),
  imageCtaTarget: optional(string()),
  imageBaseSrcPath: optional(string())
});

export const scene7configuration = object({
  useScene7Src: optional(string()),
  useImgSrcSet: optional(string()),
  scene7ImgExtension: optional(string())
});

export const galleryTrackingContent = object({
  modelName: optional(string()),
  modelYear: optional(string()),
  modelSlug: optional(string()),
  modelCarlineId: optional(string()),
  modelCategory: optional(string()),
  featureAppId: optional(string()),
  sectionName: optional(string()),
  brand: optional(string()),
});

/**
 * Main Structure Object from content
 */

export interface GalleryConfiguration {
  showCtaLabel?: string;
  galleryType?: string;
  hideCTA?: string;
  imageCtaLabel?: string;
  imageCtaUrl?: string;
  imageCtaTarget?: string;
  imageBaseSrcPath?: string;
  useScene7Src?: string,
  useImgSrcSet?: string,
  scene7ImgExtension?: string
  wasLoaded?:boolean
}
export interface Scene7Config {
  useScene7Src?: string,
  useImgSrcSet?: string,
  scene7ImgExtension?: string
}

export interface GalleryTrackingContent {
  modelName?: string;
  modelYear?: string;
  modelSlug?: string;
  modelCarlineId?: string;
  modelCategory?: string;
  featureAppId?: string;
  sectionName?: string;
  brand?: string;
}
export interface GalleryAppContent {
  rightSectionConfig?: GalleryConfiguration[];
  leftSectionConfig?: GalleryConfiguration[];
  leftSectionImages?: GalleryImage[];
  rightSectionImages?: GalleryImage[];
  trackingContent?: GalleryTrackingContent[];
  scene7Config?: Scene7Config[]
}

export const gallerySchemaObject = {
  leftSectionImages: optional(array(galleryImageObject)),
  rightSectionImages: optional(array(galleryImageObject)),
  rightSectionConfig: optional(array(galleryConfiguration)),
  leftSectionConfig: optional(array(galleryConfiguration)),
  trackingContent: optional(array(galleryTrackingContent)),
  scene7Config: optional(array(scene7configuration)),
};

export const GalleryContentSchema = object(gallerySchemaObject);

import React from 'react';
import {
  Breakpoints,
  TextAppearance,
  TextColor,
  DisableFocusWrapper,
  LinkButton,
  TextCustom,
  TextTag,
  TextSize,
  TextLineHeight,
  TextWeight,
} from '@vw-marketing/us-components';
import { Container, ImageWrapper } from './styled';
import { GalleryImage } from '../gallery-image';
import { GalleryImage as GalleryImageType } from '../../typings/gallery';

interface GalleryCallProps {
  readonly linkText: string;
  readonly galleryType: string;
  readonly onChange: any;
  readonly galleryImage?: GalleryImageType;
  readonly paddingTop?: string;
  readonly isSrcSet?: boolean;
}
const GALLERY_IMAGE_SIZES = `(max-width: ${Breakpoints.b560}px) 775px,
(max-width: ${Breakpoints.b960}px) 775px,
(max-width: ${Breakpoints.b1280}px) 775px,
(max-width: ${Breakpoints.b1600}px) 1020px,
(max-width: ${Breakpoints.b1920}px) 1020px,
(max-width: ${Breakpoints.b2560}px) 1020px`;

export const GalleryCall: React.FC<GalleryCallProps> = props => {
  const {
    linkText,
    onChange,
    galleryType,
    paddingTop,
    galleryImage,
    isSrcSet
  } = props;

  const toggleModal = (
    active: boolean,
    galleryName: string,
    type: 'button' | 'image',
  ) => {
    onChange(active, galleryName, type);
  };

  return (
    <Container className="gallery-call_wrapper" >
      <div className="image-container">
        <ImageWrapper
          onClick={toggleModal.bind(null,true, galleryType, 'image')}
          paddingTop={paddingTop}
        >
          <GalleryImage
            src={!isSrcSet && galleryImage?.path || ""}
            srcSet={isSrcSet && galleryImage?.path || ""}
            alt={galleryImage?.alt || ""}
            isMainImage={false}
            customSizesImage={GALLERY_IMAGE_SIZES}
          />
        </ImageWrapper>
      </div>
      <div className="link-container">
        <DisableFocusWrapper>
          <LinkButton
            onClick={toggleModal.bind(null,true, galleryType, 'button')}
            isInline
          >
            <TextCustom
              appearance={TextAppearance.label100}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize16,
                  lineHeight: TextLineHeight.TextLineHeight16,
                  color: TextColor.inherit,
                  fontWeight: TextWeight.bold,
                },
                [Breakpoints.b1600]: {
                  fontSize: TextSize.TextSize20,
                },
              }}
            >
              {linkText}
            </TextCustom>
          </LinkButton>
        </DisableFocusWrapper>
      </div>
    </Container>
  );
};

import {
  LayerWrapper,
  ModalLayer,
  ShimLayer,
  withFocusLock
} from '@vw-marketing/us-components';
import React from 'react';
import {StyledContainer, ModalWrapper} from './styled';

interface ModalGalleryContainer {
  readonly active: boolean;
  readonly children: React.ReactNode;
  readonly onCloseHandler: any;
}

export const ModalGalleryContainer: React.FC<ModalGalleryContainer> = props => {
  const {active, onCloseHandler, children} = props;
  const ModalLayerWithFocusLock: any = withFocusLock(ModalLayer);

  return (
    <ModalWrapper id="modal-gallery">
      <LayerWrapper>
        {active
          ? [
              <ShimLayer key="shim" />,
              <ModalLayerWithFocusLock
                key="gallery-modal"
                active={active}
                onCloseHandler={onCloseHandler}
                modalPadding="0px"
              >
                <StyledContainer>{children}</StyledContainer>
              </ModalLayerWithFocusLock>
            ]
          : undefined}
      </LayerWrapper>
    </ModalWrapper>
  );
};

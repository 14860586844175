import React from 'react';
import { StyledArrowButton } from './styled';
interface SliderArrowProps {
  type: 'next' | 'prev';
  onClick?: () => void;
  onHandleTrackingEvent?: () => void;
}
const SliderArrow: React.FC<SliderArrowProps> = props => {
  const { type, onClick, onHandleTrackingEvent } = props;

  const handleClickAction = () => {
    onClick && onClick();
    onHandleTrackingEvent && onHandleTrackingEvent();
  };
  return (
    <StyledArrowButton
      isNext={type === 'next'}
      className={type === 'next' ? 'slick-next' : 'slick-prev'}
      onClick={handleClickAction}
    />
  );
};
export default SliderArrow;

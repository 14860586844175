import {
  FeatureAppTrackingManager,
  getDefaultTrackingEvent,
} from '../hooks/use-tracking-manager';
import { GalleryImage, GalleryTrackingContent } from '../typings/gallery';

export const onHandleGalleryLoadTrackingEvent = (
  trackingManager: FeatureAppTrackingManager,
  images: GalleryImage[],
  store?: GalleryTrackingContent,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
  };

  customEvent.content = getContentEvent(
    images,
    customEvent.content,
    undefined,
    true,
  );

  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_Gallery_Load',
    store,
    customEvent,
  );
};

export const onHandleDisplayGalleryTrackingEvent = (
  target: 'button' | 'image',
  type: string,
  trackingManager: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
  images?: GalleryImage[],
  selectedIndex?: number,
) => {
  const isButton = target === 'button';
  const event = isButton ? 'VWBasic_VehicleLink_Click' : 'VWBasic_Teaser_Click';
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
  };
  if (isButton) {
    customEvent.configuration = {
      ...customEvent.configuration,
      CarlineId: store?.modelCarlineId,
      BodyTypeName: store?.modelCategory,
    };
  }
  customEvent.link = {
    ...customEvent.link,
    url: `open ${type}  modal`,
    name: `View ${type} `,
  };
  customEvent.content = getContentEvent(
    images,
    customEvent.content,
    selectedIndex,
  );
  trackingManager.trackInventoryGlobalEvent(event, store, customEvent);
};

export const onHandleGalleryLayerLoadTrackingEvent = (
  section: string,
  trackingManager: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
  images?: GalleryImage[],
  selectedImage?: number,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);

  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    pageName: `${store?.modelName}:${section}`,
  };
  customEvent.link = {
    ...customEvent.link,
    url: `display gallery layer`,
    name: `load gallery layer`,
  };
  customEvent.content = getContentEvent(
    images,
    customEvent.content,
    selectedImage,
    true,
  );
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
  };
  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_GalleryLayer_Load',
    store,
    customEvent,
  );
};

export const onHandleGallerySliderLoadTrackingEvent = (
  trackingManager: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
  galleryType?: string,
  images?: GalleryImage[],
  selectedIndex?: number,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);

  customEvent.link = {
    ...customEvent.link,
    url: `display gallery slider`,
    name: `load gallery slider`,
  };
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    pageName: `${store?.modelName}:${galleryType}`,
  };

  customEvent.content = getContentEvent(
    images,
    customEvent.content,
    selectedIndex,
  );
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
  };
  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_SliderElement_Load',
    store,
    customEvent,
  );
};

export const onHandlerGalleryLayerCloseTrackingEvent = (
  trackingManager: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);
  customEvent.link = {
    ...customEvent.link,
    url: `Close Gallery Modal`,
    name: `Close Layer`,
  };
  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_LayerClose_Click',
    store,
    customEvent,
  );
};


export const onHandleVehicleButtonTrackingEvent = (
  linkRef?: string,
  name?: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
  images?: GalleryImage[],
  selectedIndex?: number,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);
  customEvent.link = {
    ...customEvent.link,
    url: linkRef,
    name: name,
  };
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
    CarlineId: store?.modelCarlineId,
    BodyTypeName: store?.modelCategory,
  };
  customEvent.content = getContentEvent(
    images,
    customEvent.content,
    selectedIndex,
  );
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_VehicleButton_Click',
      store,
      customEvent,
    );
};

export const onHandleSliderUserActionTrackingManager = (
  action?: 'click' | 'swipe',
  isMainSlider?: boolean,
  trackingManager?: FeatureAppTrackingManager,
  store?: GalleryTrackingContent,
  galleryType?: string,
  images?: GalleryImage[],
  element?: number,
  direction?: string,
) => {
  let event = {
    event: 'VWBasic_Element_Swipe',
    url: `Swipe Carousel`,
    name: direction || 'Swipe',
  };
  if (isMainSlider && action === 'click')
    event = {
      event: 'VWBasic_Link_Click',
      url: `Move Carousel`,
      name: direction || 'click',
    };
  if (!isMainSlider && action === 'click')
    event = {
      event: 'VWBasic_Teaser_Click',
      url: `Move Carousel`,
      name: direction || 'click',
    };
  let customEvent = getDefaultTrackingEvent();
  customEvent = mergeEventTrackingContent(store, customEvent);

  customEvent.link = {
    ...customEvent.link,
    url: event.url,
    name: event.name,
  };
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: store?.modelName,
  };
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    pageName: `${store?.modelName}:${galleryType}`,
  };
  customEvent.content = getContentEvent(images, customEvent.content, element);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(event.event, store, customEvent);
};

function getFileExtension(filename: string) {
  if (!filename) return undefined;
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1]?.split('/')[0];
}
function getFileName(filename: string) {
  if (!filename) return undefined;
  var splited = filename.split && filename?.split('/');
  for (var i = splited.length - 1; i >= 0; i--) {
    if (splited[i]?.includes('.')) return splited[i];
  }
  return undefined;
}

function getFormattedAssets(
  images?: GalleryImage[],
  selectedIndex?: number,
  allImages?: boolean,
) {
  const assets: any[] = [];
  if (images?.length) {
    // images.map((image: ImageInformation) => {

    if (allImages) {
      images.map((image: GalleryImage ) => {
        const name = getFileName(image?.src || "");
        assets.push({
          Id: name ? name.replace('.', '') : undefined,
          name: name && name.split('.')[0] ? name.split('.')[0] : undefined,
          type: getFileExtension(image?.src || ""),
        });
      });
    } else {
      const image = selectedIndex && images[selectedIndex - 1];
      if (image) {
        const name = getFileName(image?.src || "");
        assets.push({
          Id: name ? name.replace('.', '') : undefined,
          name: name && name.split('.')[0] ? name.split('.')[0] : undefined,
          type: getFileExtension(image?.src || ""),
        });
      }
    }

    // });
  }
  return assets;
}

function getContentEvent(
  images?: GalleryImage[],
  event?: any,
  selectedIndex?: number,
  allImages?: boolean,
) {
  return {
    ...event,
    type: 'jpg',
    ItemCount: images?.length ? images.length : undefined,
    ItemPosition: selectedIndex,
    Asset: getFormattedAssets(images, selectedIndex, allImages) || undefined,
  };
}


const mergeEventTrackingContent = (store?: GalleryTrackingContent, customEvent?: any) => {
  if (store) {
    if (store.sectionName) { 
      customEvent.eventInfo.sectionName = store.sectionName
    }
    if (store.brand) {
      customEvent.eventInfo.brand = store.brand
    }
    if (store.featureAppId) {
      customEvent.environment.featureAppId = store.featureAppId
    }
  }
  return customEvent
}
import React from 'react';
import {
  Richtext
} from '@vw-marketing/us-components';
import {DisclaimerContainer, DisclaimerKey} from './styled';
import { disclaimerTagOverrides } from './helpers';

interface GalleryDisclaimerProps {
  readonly disclaimerText: string;
  readonly disclaimerKey: string;
}

export const GalleryDisclaimer: React.FC<GalleryDisclaimerProps> = props => {
  const { disclaimerText, disclaimerKey } = props;
  return (
    <DisclaimerContainer className="disclaimer_container">
        <DisclaimerKey className="disclaimer_key">
          <Richtext markdown={disclaimerKey} overrides={disclaimerTagOverrides} dangerouslyParseRawHTML/>
        </DisclaimerKey>
        <Richtext markdown={disclaimerText} overrides={disclaimerTagOverrides} dangerouslyParseRawHTML />
    </DisclaimerContainer>
  );
};

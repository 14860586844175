import {styled, Breakpoints} from '@vw-marketing/us-components';

export const StyledContainer: any = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: ${Breakpoints.b560}px) {
    height: 100vh;
  }
`;

export const ModalWrapper: any = styled.div`
  position: fixed;
  z-index: 999;

  &.show-animation {
    #main-slider,
    #controls {
      opacity: 1;
    }

    #thumbnails {
      opacity: 1;

      @media (min-width: ${Breakpoints.b560}px) {
        bottom: 0;
      }
    }
  }
`;

import React from 'react';

import { useFeatureServices } from './use-feature-services';

import _ from 'lodash';

export interface FeatureAppTrackingManager {
  trackInventoryGlobalEvent(
    action: string,
    state?: any,
    trackEvent?: FeatureAppTrackingEvent,
  ): void;
}

declare var __VERSION__: string;
export interface TrackingEvent {
  readonly [key: string]: object;
}

type Brand = 'passenger' | 'commercial';

export interface FeatureAppTrackingEvent {
  eventInfo?: {
    contentId?: string;
    pageCategory?: string;
    sectionName?: string;
    templateType?: string;
    brand?: Brand;
    contentLabels?: [];
    pageName?: string;
  };

  environment?: {
    featureAppIdAppVersion?: string;
    region?: string;
    featureAppId?: string;
  };

  user?: {
    loginStatus?: boolean;
    locationZip?: string;
    locationLatitude?: string;
    locationLongitude?: string;
    loginId?: string;
  };
  content?: {
    Asset?: any[];
    Type?: string;
    Name?: string;
    ItemCount?: number;
    ItemPosition?: string;
  };
  personalization?: {
    status?: boolean;
    moduleId?: string;
    personalizable?: boolean;
    placeholderId?: string;
    contentId?: string;
    activityId?: string;
    activityName?: string;
    experienceId?: string;
    experienceName?: string;
    offerId?: string;
    offerName?: string;
  };

  configuration?: {
    BodyTypeName?: string;
    CarlineId?: string | string[];
    CarlineName?: string | string[];
    CarlineIsRecommendation?: string;
    ModelName?: string | string[];
    SalesgroupId?: string | string[];
    SalesgroupName?: string | string[];
    SalesgroupIsRecommendation?: string;
    EquipmentlineName?: string;
    EquipmentlineIsRecommendation?: string;
    ModelId?: string | string[];
    ModelTrimFeatures?: string;
    ModelYear?: string;
    WheelsAdditionalExternalRollingNoise?: string;
    PriceType?: string;
    PriceTypeParameter?: [];
    PriceRateCurrency?: string;
    PriceRateType?: string;
    PriceRateBasic?: string;
    PriceRateAdditional?: string;
    PriceRateTotal?: string;
    PriceTotalCurrency?: string;
    PriceTotalBasic?: string;
    PriceTotalAdditional?: string;
    PriceTotalTotal?: string;
    PriceCampaignID?: string;
    PriceCampaignDescription?: string;
    PriceCampaignValue?: string;
    vehicleidentificationnumber?: string;
  };

  partner?: {
    informationBnr?: string;
    informationName?: string;
    informationDepartment?: string;
    informationZIP?: string;
    informationDistance?: number;
    SearchNumberOfResults?: number;
    onlinePaymentOption?: boolean;
    appointmentSystemName?: string;
    appointmentSystemUtilization?: boolean;
  };

  form?: {
    url?: string;
    name?: string;
    FormType?: string;
    FormTypeMultiple?: [];
    FormName?: string;
    NumberOfAppointments?: number;
    Appointments?: [];
    FormFieldName?: string;
    TextFieldValue?: [];
    PrefContactChannels?: string;
    FormFields?: [];
    ErrorFormFields?: [];
    ErrorAdressValidationFields?: [];
    FormVersion?: string;
    MarketingConsent?: [];
    OpportunityUID?: string;
    orderId?: string;
    LeadID?: string;
    NewsletterType?: [];
    FormStart?: boolean;
    CycleID?: string;
    Questionnaire?: [];
  };

  process?: {
    Name?: string;
    StepName?: string;
    StepNumber?: string;
    Components?: [];
  };

  link?: {
    url?: string;
    name?: string;
    newTab?: boolean;
  };

  error?: {
    code?: string;
    message?: string;
    referringUrl?: string;
  };

  video?: {
    playerName?: string;
    title?: string;
    id?: string;
    platform?: string;
    url?: string;
    milestone?: number;
    timeplayed?: number;
    length?: number;
  };

  filter?: {
    results?: [];
    numberOfResults?: number;
    filterlist?: any[];
    sortOrder?: any[];
    type?: string;
    name?: string;
    values?: any[];
    lowerbound?: string;
    upperbound?: string;
  };
}

const defaultTrackingEvent: FeatureAppTrackingEvent = {
  eventInfo: {
    brand: 'passenger',
    sectionName: 'Gallery'
  },
  
  environment: {
    featureAppIdAppVersion: __VERSION__,
    featureAppId: 'Gallery-D6-US',
  }
};

export function getDefaultTrackingEvent() {
  return _.cloneDeep(defaultTrackingEvent);
}

function buildTrackingEvent(
  cusTrackEvent?: FeatureAppTrackingEvent,
): FeatureAppTrackingEvent {
  let trackingEvent: FeatureAppTrackingEvent;
  
  if (cusTrackEvent) {
    trackingEvent = _.cloneDeep(cusTrackEvent);
  } else {
    trackingEvent = _.cloneDeep(defaultTrackingEvent);
  }

  return trackingEvent;
}

export function getTrackEventClone(): FeatureAppTrackingEvent {
  return _.cloneDeep(defaultTrackingEvent);
}

export function useTrackingManager(): FeatureAppTrackingManager {
  const { tracking: trackingManager } = useFeatureServices() as any;

  return React.useMemo((): FeatureAppTrackingManager => {
    function track(action: string, event: FeatureAppTrackingEvent): void {
      if (trackingManager) {
        trackingManager.track(action, (event as unknown) as TrackingEvent);
      }
    }
    return {
      trackInventoryGlobalEvent(
        event: string,
        state?: any,
        trackEvent?: FeatureAppTrackingEvent,
      ): void {
        // store general tracking 
        const trackEventGlobal = buildTrackingEvent(trackEvent) || state;
        track(event, trackEventGlobal || defaultTrackingEvent);
      },
    };
  }, [trackingManager]);
}

import {LoaderContainer, LoaderSpinner} from '@vw-marketing/us-components';
import React from 'react';
import {GalleryCall} from '../../components/gallery-call';
import {HandleDataLoad} from '../../components/handle-data-load';
import {useFeatureAppContent} from '../../hooks/use-feature-app-content';
import {useTrackingManager} from '../../hooks/use-tracking-manager';
import {GalleryConfiguration, GalleryImage} from '../../typings/gallery';
import {unsetPagemainZindex} from '../../utils/app';
import {
  onHandleDisplayGalleryTrackingEvent,
  onHandleGalleryLoadTrackingEvent,
} from '../../utils/tracking-helpers';
import {getGallerySectionsContent} from './helpers';
import {GalleryAppContainer, GalleryContainer} from './styled';
import {fetchContentGalleryImages} from '../../fetch-data/fetch-gallery-images';

type GalleryAppStatus = 'loading' | 'error' | 'loaded';

export interface GallerySection extends GalleryConfiguration {
  images: GalleryImage[];
  type: 'left' | 'right';
}
export interface GalleryAppState {
  status: GalleryAppStatus;
  leftContent?: GallerySection;
  rightContent?: GallerySection;
  selectedGallery?: GallerySection;
}
const GalleryApp = () => {
  let loadingMessage = 'Loading Gallery...';

  const [modalActive, setModalActive] = React.useState(false);
  const [appGallery, setAppGallery] = React.useState<GalleryAppState>({
    status: 'loading',
  });
  const galleryAppContent = useFeatureAppContent();
  const {scene7Config, trackingContent: appTrackingContent} = galleryAppContent;
  const isWithSrcSet =
    scene7Config?.length &&
    scene7Config[0]?.useScene7Src === 'true' &&
    scene7Config[0]?.useImgSrcSet === 'true'
      ? true
      : false;
  const trackingContent = appTrackingContent?.length
    ? appTrackingContent[0]
    : undefined;
  const trackingManager = useTrackingManager();

  const fetchGallerySectionImages = async (
    selectedGallery?: GallerySection,
  ): Promise<GallerySection> => {
    if (selectedGallery) {
      const {images} = selectedGallery;
      const scene7ConfigElement =
        scene7Config && scene7Config.length ? scene7Config[0] : undefined;
      const fetchedImages = await fetchContentGalleryImages(
        images,
        selectedGallery,
        scene7ConfigElement,
      );

      return {...selectedGallery, images: fetchedImages, wasLoaded: true};
    }
    return Promise.reject();
  };

  React.useEffect(() => {
    // Avoid the navigation menu overlap issue
    getGallerySectionsContent(galleryAppContent)
      .then((galleryResponse) => {
        setAppGallery(galleryResponse);
      })
      .catch(() => {
        setAppGallery({...appGallery, status: 'error'});
      });
    unsetPagemainZindex();
  }, []);

  React.useEffect(() => {
    if (appGallery.status === 'loaded') {
      // Tracking code
      const imagesTracking: GalleryImage[] = [];
      if (appGallery?.leftContent?.images[0]) {
        imagesTracking.push(appGallery?.leftContent?.images[0]);
      }
      if (appGallery?.rightContent?.images[0]) {
        imagesTracking.push(appGallery?.rightContent?.images[0]);
      }
      onHandleGalleryLoadTrackingEvent(
        trackingManager,
        imagesTracking,
        trackingContent,
      );
    }
  }, [appGallery.status]);

  React.useEffect(() => {
    //Hide body scroll when modal is active and add animation gallery
    if (modalActive) {
      document.body.style.overflow = 'hidden';
      document.querySelector('#modal-gallery')?.classList.add('show-animation');
    } else {
      document.body.style.overflow = 'unset';
      document
        .querySelector('#modal-gallery')
        ?.classList.remove('show-animation');
    }
  }, [modalActive]);

  const handleChange = (
    active: boolean,
    type: string,
    target: 'button' | 'image',
  ) => {
    const galleryKey = type === 'left' ? 'leftContent' : 'rightContent';
    if (appGallery[galleryKey]?.wasLoaded) {
      setAppGallery({
        ...appGallery,
        selectedGallery: appGallery[galleryKey],
      });
      setModalActive(active);
    } else {
      fetchGallerySectionImages(appGallery[galleryKey]).then((newGallery) => {
        setAppGallery({
          ...appGallery,
          selectedGallery: newGallery,
          [galleryKey]: newGallery,
        });
        setModalActive(active);
      });
    }

    // Tracking code
    const imagesTracking: GalleryImage[] = [];
    if (appGallery?.leftContent?.images[0]) {
      imagesTracking.push(appGallery?.leftContent?.images[0]);
    }
    if (appGallery?.rightContent?.images[0]) {
      imagesTracking.push(appGallery?.rightContent?.images[0]);
    }
    onHandleDisplayGalleryTrackingEvent(
      target,
      type,
      trackingManager,
      trackingContent,
      imagesTracking,
      type === 'left' ? 1 : 2,
    );
  };

  return (
    <GalleryAppContainer id="galleryFA">
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {appGallery.status === 'loading' && (
        <LoaderContainer>
          <LoaderSpinner label={loadingMessage} />
        </LoaderContainer>
      )}
      {appGallery.status === 'loaded' && (
        <>
          <GalleryContainer className="gallery-container">
            {appGallery.leftContent && (
              <GalleryCall
                galleryType="left"
                linkText={appGallery.leftContent?.showCtaLabel || ''}
                onChange={handleChange}
                galleryImage={appGallery.leftContent?.images[0]}
                isSrcSet={isWithSrcSet}
              />
            )}
            {appGallery.rightContent && (
              <GalleryCall
                galleryType="right"
                linkText={appGallery.rightContent.showCtaLabel || ''}
                onChange={handleChange}
                galleryImage={appGallery.rightContent?.images[0]}
                isSrcSet={isWithSrcSet}
              />
            )}
          </GalleryContainer>
          {appGallery.selectedGallery && (
            <HandleDataLoad
              modalActive={modalActive}
              setModalActive={setModalActive}
              currentGallery={appGallery.selectedGallery}
              trackingContent={trackingContent}
              isWithSrcSet={isWithSrcSet}
            />
          )}
        </>
      )}
    </GalleryAppContainer>
  );
};

export default GalleryApp;

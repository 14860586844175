import { SlickSettingsModel } from "../../typings/general";

export const SLICK_SETTINGS_1: SlickSettingsModel = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  export const SLICK_SETTINGS_2: SlickSettingsModel = {
    arrows: false,
    focusOnSelect: true,
    slidesToShow: 5,
    infinite: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
import React from 'react';

import {
  Breakpoints,
  TextCustom,
  TextSize,
  TextWeight,
  TextLineHeight,
  TextAppearance,
  TextColor,
  Container,
  ContainerPadding,
  ButtonCustomLink,
  DisableFocusWrapper,
  ButtonAppearance,
  Richtext,
} from '@vw-marketing/us-components';

import {
  BuildYours,
  Controls,
  GalleryStatus,
  GalleryType,
  GalleryCounter,
  GalleryCutline,
  GalleryDisclaimersContainer,
  SlideInformation,
} from './styled';
import {buttonPaddingConfig, cutlineTextStyle} from './helpers';
import {GalleryDisclaimer} from '../gallery-disclaimer';

interface GalleryControlsProps {
  readonly galleryType?: string;
  readonly galleryCutline?: string;
  readonly galleryDisclaimers?: any;
  readonly slideCounter?: string;
  readonly setModalActive?: any;
  readonly onTrackingHandle?: (name?: string) => void;
  readonly hideCTA?: boolean | undefined;
  readonly redirectURL?: {
    readonly imageCtaLabel: string;
    readonly imageCtaTarget: string;
    readonly imageCtaUrl: string;
  };
}

export const GalleryInfo: React.FC<GalleryControlsProps> = props => {
  const {
    galleryType,
    galleryCutline,
    galleryDisclaimers,
    slideCounter,
    setModalActive,
    onTrackingHandle,
    hideCTA,
    redirectURL,
  } = props;
  const isExternalUrl = redirectURL?.imageCtaTarget !== '_handle_self_page';

  const clickBuildYourOwn = (name: string): void => {
    setModalActive && setModalActive(false);
    if (!isExternalUrl) {
      setTimeout(() => {
        const element = document.querySelector(redirectURL?.imageCtaUrl || '');
        // NOTE: Error on Chrome scrolling using smooth
        const isChrome =
          navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        if (element) {
          element.scrollIntoView({
            block: 'start',
            behavior: isChrome ? 'auto' : 'smooth',
          });
        }
      }, 1000);
    }
    onTrackingHandle && onTrackingHandle(name);
  };

  return (
    <Controls id="controls">
      <SlideInformation className="gallery-info_slide-information">
        <GalleryStatus className="gallery-info_gallery-status">
          <GalleryType className="gallery-info_gallery-type">
            <TextCustom
              appearance={TextAppearance.copy100}
              style={{
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize20,
                lineHeight: TextLineHeight.TextLineHeight20,
              }}
            >
              {galleryType}
            </TextCustom>
          </GalleryType>
          <GalleryCounter className="gallery-info_counter">
            <TextCustom
              appearance={TextAppearance.copy100}
              style={{
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize16,
                lineHeight: TextLineHeight.TextLineHeight16,
              }}
            >
              {slideCounter}
            </TextCustom>
          </GalleryCounter>
        </GalleryStatus>
        <GalleryCutline className="gallery-info_cutline">
          <Richtext
            markdown={galleryCutline || ''}
            overrides={cutlineTextStyle}
            dangerouslyParseRawHTML
          />
        </GalleryCutline>
        {galleryDisclaimers && (
          <GalleryDisclaimersContainer className="gallery-info_disclaimers">
            {galleryDisclaimers.map((disclaimer: any) => (
              <GalleryDisclaimer
                disclaimerKey={disclaimer.key}
                disclaimerText={disclaimer.text}
                key={disclaimer.key}
              />
            ))}
          </GalleryDisclaimersContainer>
        )}
      </SlideInformation>
      <BuildYours className="gallery-info_redirect-cta">
        <Container
          horizontalAlign={{
            [Breakpoints.default]: 'center',
            [Breakpoints.b960]: 'flex-start',
          }}
          padding={{
            bottom: {
              [Breakpoints.default]: ContainerPadding.static200,
              [Breakpoints.b960]: ContainerPadding.static0,
            },
          }}
        >
          {!hideCTA && (
            <DisableFocusWrapper>
              <ButtonCustomLink
                appearance={ButtonAppearance.Secondary}
                stretchContent
                padding={buttonPaddingConfig}
                onClick={clickBuildYourOwn.bind(
                  null,
                  redirectURL?.imageCtaLabel || '',
                )}
                href={(isExternalUrl && redirectURL?.imageCtaUrl) || undefined}
                target={isExternalUrl && (redirectURL?.imageCtaTarget as any)}
              >
                <TextCustom
                  appearance={TextAppearance.label150}
                  style={{
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight16,
                    color: TextColor.inherit,
                  }}
                >
                  {redirectURL?.imageCtaLabel}
                </TextCustom>
              </ButtonCustomLink>
            </DisableFocusWrapper>
          )}
        </Container>
      </BuildYours>
    </Controls>
  );
};

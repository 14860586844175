import {styled, Breakpoints} from '@vw-marketing/us-components';

export const GalleryAppContainer: any = styled.div`
  width: 100%;
`;

export const GalleryContainer: any = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 30px;
  @media (min-width: ${Breakpoints.b560}px) {
    flex-direction: row;
    row-gap: none;
    justify-content: space-around;
  }
`;

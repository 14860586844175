import 'isomorphic-fetch';
import {
  GalleryConfiguration,
  GalleryImage,
  Scene7Config,
} from '../typings/gallery';
import { getImageStructure, getScene7Extension } from './helpers';


export async function fetchContentGalleryImages(
  galleryImages: GalleryImage[],
  galleryContent?: GalleryConfiguration,
  scene7Config?: Scene7Config,
): Promise<GalleryImage[]> {
  const scene7extension = scene7Config && getScene7Extension(scene7Config);
  const images: GalleryImage[] = [];

  for await (const image of galleryImages) {
    const imagePath = await getImageStructure(
      image,
      scene7extension || "",
      galleryContent?.imageBaseSrcPath,
      typeof scene7extension !== "undefined",
    );
    images.push(imagePath);
  }
  return images;
}

import {
  createTagOverride,
  TextTag,
  TextAppearance,
  TextColor,
  Link,
  LinkProps,
  TextCustom,
  TextWeight,
} from '@vw-marketing/us-components';

export const disclaimerTagOverrides = {
  span: createTagOverride(TextCustom, {
    tag: TextTag.span,
    appearance: TextAppearance.copy100,
    style: {
      color: TextColor.inherit
    }
  }),
  div: createTagOverride(TextCustom, {
    tag: TextTag.div,
    appearance: TextAppearance.copy100,
    style: {
      color: TextColor.inherit
    }
  }),
  p: createTagOverride(TextCustom, {
    tag: TextTag.p,
    appearance: TextAppearance.copy100,
    style: {
      color: TextColor.inherit
    }
  }),
  strong: createTagOverride(TextCustom, {
    tag: TextTag.strong,
    appearance: TextAppearance.copy100,
    style: {
      fontWeight: TextWeight.bold,
      color: TextColor.inherit
    },
  }),
  a: createTagOverride(Link, {
    isInline: false,
    target: '_blank',
    color: TextColor.inherit,
  } as LinkProps),
};

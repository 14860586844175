import {
  BreakpointConfigType,
  Breakpoints,
  createTagOverride,
  DirectionalConfigType,
  LengthCss,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

const textStyle = {
  appearance: TextAppearance.copy100,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.regular,
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight14,
      color: TextColor.inherit,
    },
    [Breakpoints.b560]: {
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight16,
    },
  },
};
const textStrongStyle = {
  appearance: TextAppearance.copy100,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight14,
      color: TextColor.inherit,
    },
    [Breakpoints.b560]: {
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight16,
    },
  },
};

export const cutlineTextStyle = {
  div: createTagOverride(TextCustom, {...textStyle, tag: TextTag.div}),
  span: createTagOverride(TextCustom, {...textStyle, tag: TextTag.span}),
  p: createTagOverride(TextCustom, {...textStyle, tag: TextTag.p}),
  strong: createTagOverride(TextCustom, {
    ...textStrongStyle,
    tag: TextTag.strong,
  }),
};

/**
 * Padding configuration for buttons
 */
const verticalPadding: BreakpointConfigType<LengthCss> = {
  [Breakpoints.default]: {length: 15, unit: 'px'},
  [Breakpoints.b960]: {length: 15, unit: 'px'},
};
const horizontalPadding: BreakpointConfigType<LengthCss> = {
  [Breakpoints.default]: {length: 20, unit: 'px'},
  [Breakpoints.b960]: {length: 35, unit: 'px'},
};
export const buttonPaddingConfig: DirectionalConfigType<
  BreakpointConfigType<LengthCss>
> = {
  top: verticalPadding,
  bottom: verticalPadding,
  left: horizontalPadding,
  right: horizontalPadding,
};

import {
  FeatureAppDefinition,
} from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { ThemeProvider } from '@vw-marketing/us-components';
import React from 'react';
import GalleryApp from './app/standalone';
import { FeatureAppEnvironmentContext } from './hooks/use-feature-app-environment';
import { FeatureAppConfig, FeatureAppDependencies } from './typings/fa-environment-config';

declare var __VERSION__: String;

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:serialized-state-manager': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'locale-service': '^1.0.0',
      'service-config-provider': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'tracking': '^1.0.0',
      'fa-content-service': '^1.0.0',
    },
  },

  create: env => {
    return {
      render: () => (
          <FeatureAppEnvironmentContext.Provider value={env}>
            <ThemeProvider theme="main">
              <div className='gallery-app-version' style={{"display":"none"}}>
                Feature App Version :: Gallery :: {__VERSION__}
              </div>
              <GalleryApp />
            </ThemeProvider>
          </FeatureAppEnvironmentContext.Provider>
      ),
    };
  },
};

export default featureAppDefinition;

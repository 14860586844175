(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "styled-components", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["feature-app"] = factory(require("react"), require("styled-components"), require("react-dom"));
	else
		root["feature-app"] = factory(root["react"], root["styled-components"], root["react-dom"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__37__) {
return 
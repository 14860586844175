import * as s from 'superstruct';
import { DEFAULT_MODE_CONTENT } from '../config/default_content';
import { GalleryAppContent, GalleryContentSchema } from '../typings/gallery';
import { useFeatureServices } from './use-feature-services';

export type GalleryContentSchema = s.Infer<typeof GalleryContentSchema>;

export function useFeatureAppContent(): GalleryAppContent {
  const { 'fa-content-service': contentService } = useFeatureServices() as any;
  const content = contentService?.content;
  const [err] = s.validate(content, GalleryContentSchema);
  if (err) {
    console.log(
      'Schema contains invalid data:',
      err.message,
      'schema',
      content,
    );
  }
  return s.is(content, GalleryContentSchema)
    ? (content as GalleryAppContent)
    : DEFAULT_MODE_CONTENT;
}


export const convertCutline = (cutline: string) => {
  let disclaimerRegex = /\${(.*?)\}/;
  while (disclaimerRegex.exec(cutline) !== null) {
    let key = disclaimerRegex.exec(cutline);
    let ref = key ? key[1] : '';
    cutline = cutline.replace(disclaimerRegex, '<sup>&nbsp;' + ref + '</sup>');
  }
  return cutline;
};

/**
 * Unsets z-index value for pagemain container
 */
export const unsetPagemainZindex = () => {
  // The class name is used due to lack of id
  let pagemainContainer = document.getElementsByClassName('pagemain')[0];
  if (pagemainContainer && pagemainContainer.parentElement)
    pagemainContainer.parentElement.style.zIndex = 'unset';
};

import {styled, Breakpoints} from '@vw-marketing/us-components';

export const DisclaimerContainer: any = styled.div`
  margin-top: 5px;
  width: 100%;
  color: #ffffff;
  display: flex;
  
  a {
    color: #ffffff;
    font-weight: bold;
    
    &:hover, &:focus {
      color: #ffffff;
    }
  }

  @media (max-width: ${Breakpoints.b560}px) {
    color: #000000;
    a {
      color: #000000;
  
      &:hover, &:focus {
        color: #000000;
      }
    }
  }
`;

export const DisclaimerKey: any = styled.div`
  display: flex;
  margin-right: 10px;
`;
import {SpinnerIcon, Text, TextAppearance, TextTag} from '@vw-marketing/us-components';
import React from 'react';
import {StyledLoaderWrapper, TextWrapper} from './styled';

interface LoaderSpinnerProps {
  readonly label?: string;
  readonly showSpinner: boolean;
}

export const LoaderSpinner: React.FC<LoaderSpinnerProps> = (
  props,
): JSX.Element => {
  const {label, showSpinner} = props;

  return (
    <StyledLoaderWrapper>
      {showSpinner && <SpinnerIcon />}
      <TextWrapper>
        <Text appearance={TextAppearance.copy200} tag={TextTag.span}>
          {label ? label : 'Loading...'}
        </Text>
      </TextWrapper>
    </StyledLoaderWrapper>
  );
};

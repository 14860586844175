import {Breakpoints, Image} from '@vw-marketing/us-components';
import React, {useEffect, useState} from 'react';
import {AppConstants} from '../../utils/app-constants';
import isBrowser from "is-browser";

export interface GalleryImageProps {
  readonly src: string;
  readonly alt: string;
  readonly srcSet?: string;
  readonly isMainImage: boolean;
  readonly customSizesImage?: string;
}

const MAIN_IMAGES_SIZES = `
(max-width: ${Breakpoints.b560}px) 959px, 
(max-width: ${Breakpoints.b960}px) 959px,
(max-width: ${Breakpoints.b1280}px) 1919px,
(max-width: ${Breakpoints.b1600}px) 1919px,
(max-width: ${Breakpoints.b1920}px) 1919px,
(max-width: ${Breakpoints.b2560}px) 2559px`;

const THUMBNAIL_IMAGES_SIZES = `(max-width: ${Breakpoints.b560}px) 266px,
(max-width: ${Breakpoints.b960}px) 266px,
(max-width: ${Breakpoints.b1280}px) 470px,
(max-width: ${Breakpoints.b1600}px) 470px,
(max-width: ${Breakpoints.b1920}px) 470px,
(max-width: ${Breakpoints.b2560}px) 470px
 `;
export const GalleryImage: React.FC<GalleryImageProps> = (
  props,
): JSX.Element => {
  const {src, alt, srcSet, isMainImage} = props;
  const [onErrorImage, setOnErrorImage] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  const [loaded, setLoaded] = useState(false);

  const srcErrorImage = isMainImage
    ? AppConstants.PlaceHolder960x432
    : AppConstants.PlaceHolder480x216;
  const srcPlaceholder = isMainImage
    ? AppConstants.PlaceHolder960x432
    : AppConstants.PlaceHolder480x216;

  useEffect(() => {
    if (onErrorImage && imgSrc !== srcErrorImage && imgSrc !== srcPlaceholder) {
      setImgSrc(srcErrorImage ? srcErrorImage : srcPlaceholder);
    }
  }, [onErrorImage]);

  useEffect(() => {
    if (src !== imgSrc) {
      setImgSrc(src);
      setLoaded(false);
      setOnErrorImage(false);
    }
  }, [src]);

  return onErrorImage ? (
    <Image alt={alt} src={imgSrc} />
  ) : (
    <>
      <Image
        alt={alt}
        src={srcPlaceholder}
        style={loaded ? {display: 'none'} : {}}
      />
      {isBrowser &&  (
        <Image
          alt={alt}
          src={imgSrc}
          srcSet={srcSet}
          sizes={
            srcSet
              ? isMainImage
                ? MAIN_IMAGES_SIZES
                : THUMBNAIL_IMAGES_SIZES
              : undefined
          }
          onError={setOnErrorImage.bind(null, true)}
          onLoad={setLoaded.bind(null, true)}
          style={loaded ? {} : {display: 'none'}}
        />
      )}
    </>
  );
};

import {
  TextCustom,
  TextAppearance,
  TextWeight,
  TextSize,
  TextLineHeight,
} from '@vw-marketing/us-components';
import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import {GalleryInfo} from '../gallery-info';
import {
  GalleryAppContainer,
  TopSlider,
  BottomSlider,
  SlickSlide,
  GalleryType,
} from './styled';
import {SlickSettingsModel} from '../../typings/general';
import {FeatureAppTrackingManager} from '../../hooks/use-tracking-manager';
import {
  onHandleGallerySliderLoadTrackingEvent,
  onHandleSliderUserActionTrackingManager,
  onHandleVehicleButtonTrackingEvent,
} from '../../utils/tracking-helpers';
import SliderArrow from './slider-arrow';
import {GallerySection} from '../../app/standalone';
import {GalleryTrackingContent} from '../../typings/gallery';
import {GalleryImage} from '../gallery-image';

interface SlickSliderProps {
  readonly settings1: SlickSettingsModel;
  readonly settings2: SlickSettingsModel;
  readonly setModalActive: any;
  readonly trackingManager?: FeatureAppTrackingManager;
  readonly trackingContent?: GalleryTrackingContent;
  readonly gallery: GallerySection;
  readonly isWithSrcSet: boolean;
}

export const GallerySlider: React.FC<SlickSliderProps> = (props) => {
  const {
    settings1,
    settings2,
    setModalActive,
    gallery,
    trackingManager,
    trackingContent,
    isWithSrcSet,
  } = props;

  const {
    images = [],
    hideCTA,
    imageCtaLabel = '',
    imageCtaTarget = '',
    imageCtaUrl = '',
    galleryType = '',
  } = gallery;
  const [topNav, setTopNav]: any = useState(null);
  const [bottomNav, setBottomNav]: any = useState(null);
  const [slideCounter, setSlideCounter]: any = useState(
    '1 of ' + images.length,
  );
  const [currentSlide, setCurrentSlide] = useState(1);
  const [galleryCutline, setGalleryCutline]: any = useState(images[0]?.cutline);
  const [galleryDisclaimers, setGalleryDisclaimers]: any = useState(
    images[0]?.disclaimers,
  );
  let _topSlider: any, _bottomSlider: any;

  const afterSlideChange = (current: any) => {
    let counter = current + ' of ' + images.length;
    let cutline = images[current - 1]?.cutline;
    let disclaimers = images[current - 1]?.disclaimers;
    setCurrentSlide(current);
    setSlideCounter(counter);
    setGalleryCutline(cutline);
    setGalleryDisclaimers(disclaimers);
    trackingManager && onHandleGallerySliderLoadTrackingEvent(
      trackingManager,
      trackingContent,
      galleryType,
      images,
      current,
    );
  };

  const onHandleSlideChangeTracking = (
    type: 'main' | 'thumbnails',
    action: 'click' | 'swipe',
    element?: number,
    direction?: 'nav_left' | 'nav_right',
    isArrow?: boolean,
  ) => {
    let newElement: number | undefined = element;
    let newDirection: 'nav_left' | 'nav_right' | undefined = direction;
    const total = images.length;
    if (!isArrow && !element && direction) {
      newElement =
        direction === 'nav_right' ? currentSlide - 1 : currentSlide + 1;
      newElement = newElement % total; // this gives
      newElement = newElement === 0 ? total : newElement; // 0 means that the position is on limit
    }
    trackingManager && onHandleSliderUserActionTrackingManager(
      action,
      type === 'main',
      trackingManager,
      trackingContent,
      galleryType,
      images,
      newElement,
      newDirection,
    );
  };

  const onHandleMainSliderArrowsAction = (
    type: 'next' | 'prev',
    slide: number,
  ) => {
    const isNext = type === 'next';
    const total = images.length;
    let newElement = !isNext ? slide - 1 : slide + 1;
    newElement = newElement % total; // this gives
    newElement = newElement === 0 ? total : newElement; // 0 means that the position is on limit

    onHandleSlideChangeTracking(
      'main',
      'click',
      newElement,
      isNext ? 'nav_right' : 'nav_left',
    );
  };

  const slickSettings1: SlickSettingsModel = {
    ...settings1,
    afterChange: (current: any) => afterSlideChange(current + 1),
    onSwipe: (slider: string) => {
      onHandleSlideChangeTracking(
        'main',
        'swipe',
        undefined,
        (slider && (slider === 'right' ? 'nav_right' : 'nav_left')) ||
          undefined,
      );
    },
    nextArrow: (
      <SliderArrow
        type={'next'}
        onHandleTrackingEvent={onHandleMainSliderArrowsAction.bind(
          null,
          'next',
          currentSlide,
        )}
      />
    ),
    prevArrow: (
      <SliderArrow
        type={'prev'}
        onHandleTrackingEvent={onHandleMainSliderArrowsAction.bind(
          null,
          'prev',
          currentSlide,
        )}
      />
    ),
  };

  const slickSettings2: SlickSettingsModel = {
    ...settings2,
    onSwipe: (slider: string) => {
      onHandleSlideChangeTracking(
        'thumbnails',
        'swipe',
        undefined,
        (slider && (slider === 'right' ? 'nav_right' : 'nav_left')) ||
          undefined,
      );
    },
  };

  const createSlider = (isMainSlider: boolean) => {
    let result: any[] = [];
    for (let i = 0; i < images.length; i++) {
      let path = images[i]?.path;
      let alt = images[i]?.alt;
      result.push(
        <SlickSlide
          key={'slide-slick-' + i}
          onClick={() => {
            !isMainSlider &&
              onHandleSlideChangeTracking('thumbnails', 'click', i + 1);
          }}
        >
          <GalleryImage
            src={!isWithSrcSet && path ? path : ''}
            alt={alt || ''}
            srcSet={isWithSrcSet && path ? path : ''}
            isMainImage={isMainSlider}
          />
        </SlickSlide>,
      );
    }
    return result;
  };

  useEffect(() => {
    setTopNav(_topSlider);
    setBottomNav(_bottomSlider);
  }, [_topSlider, _bottomSlider]);

  const onHandleBuildYourTrackingEvent = (name?: string) => {
    trackingManager && onHandleVehicleButtonTrackingEvent(
      window.location.href,
      name,
      trackingManager,
      trackingContent,
      images,
      currentSlide,
    );
  };

  return (
    <>
      <GalleryType className="gallery-type">
        <TextCustom
          appearance={TextAppearance.headline200}
          style={{
            fontWeight: TextWeight.bold,
            fontSize: TextSize.TextSize32,
            lineHeight: TextLineHeight.TextLineHeight32,
          }}
        >
          {galleryType}
        </TextCustom>
      </GalleryType>
      <GalleryAppContainer className="gallery-app-container">
        <TopSlider id="main-slider">
          <Slider
            {...slickSettings1}
            asNavFor={bottomNav}
            ref={(slider) => {
              _topSlider = slider;
            }}
          >
            {createSlider(true)}
          </Slider>
        </TopSlider>
        <BottomSlider id="thumbnails">
          <Slider
            {...slickSettings2}
            asNavFor={topNav}
            ref={(slider) => {
              _bottomSlider = slider;
            }}
          >
            {createSlider(false)}
          </Slider>
        </BottomSlider>
      </GalleryAppContainer>
      <GalleryInfo
        setModalActive={setModalActive}
        galleryType={galleryType}
        slideCounter={slideCounter}
        galleryCutline={galleryCutline}
        galleryDisclaimers={galleryDisclaimers}
        onTrackingHandle={onHandleBuildYourTrackingEvent}
        hideCTA={hideCTA === 'true'}
        redirectURL={{imageCtaLabel, imageCtaTarget, imageCtaUrl}}
      />
    </>
  );
};

import { styled, Breakpoints } from '@vw-marketing/us-components';

export const GalleryAppContainer: any = styled.div`
  width: 100%;
  height: 295px;

  @media (min-width: ${Breakpoints.b560}px) {
    height: 100vh;
    overflow: hidden;
  }
`;

export const TopSlider: any = styled.div`
  width: 100%;
  height: 80%;
  opacity: 0;
  transition: opacity 500ms ease 500ms;

  @media (max-width: ${Breakpoints.b560}px) {
    height: 224px;
  }

  .slick-slide div div {
    box-shadow: inset 0px -100px 100px 0px rgba(0, 0, 0, 0.5);
    img {
      z-index: -2;
      position: relative;
    }
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide img,
  .slick-slide div,
  .slick-slide div div {
    height: 100%;
  }

  .slick-next,
  .slick-prev {
    right: 100px;
    top: unset;
    bottom: 8%;
    left: unset;
    width: 22px;
    height: 22px;
    z-index: 10;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 7px;

    &::before {
      display: none;
    }
  }

  .slick-next {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .slick-prev {
    margin-right: 30px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
`;

export const BottomSlider: any = styled.div`
  width: 100%;
  height: 71px;
  opacity: 0;
  transition: opacity 500ms ease 500ms;

  @media (min-width: ${Breakpoints.b560}px) {
    height: 20%;
    position: relative;
    bottom: -20%;
    transition: bottom 1000ms ease 100ms, box-shadow 1000ms ease 100ms;
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide img,
  .slick-slide div,
  .slick-slide div div {
    height: 100%;
  }

  .slick-track {
    margin: auto;
  }

  .slick-slide {
    opacity: 0.5;
    padding: 15px 15px 0 0;

    @media (max-width: ${Breakpoints.b560}px) {
      padding: 10px 10px 0 0;
    }
  }

  .slick-current {
    opacity: 1;

    div div {
      border: 3px solid #00b0f0;
    }
  }
`;

export const SlickSlide: any = styled.div`
  outline: none;

  img {
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
  }
`;

export const GalleryType: any = styled.div`
  margin: 60px 20px 41px;
  display: block;
  @media (min-width: ${Breakpoints.b560}px) {
    display: none;
  }
`;

interface StyledArrowButtonProps {
  isNext?: boolean;
}
export const StyledArrowButton: any = styled.button<StyledArrowButtonProps>``;

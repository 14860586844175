import 'isomorphic-fetch';
import {
  GalleryConfiguration,
  GalleryImage,
  Scene7Config,
} from '../typings/gallery';
import {getImageStructure, getScene7Extension} from './helpers';

export async function fetchInitialContentGalleryImage(
  galleryImages: GalleryImage[],
  galleryContent?: GalleryConfiguration,
  scene7Config?: Scene7Config,
): Promise<GalleryImage[]> {
  const scene7extension = scene7Config && getScene7Extension(scene7Config);
  let newGalleryImages: GalleryImage[] = [];
  if (!galleryImages.length || !galleryImages[0]) return galleryImages;
  const newGalleryImage = await getImageStructure(
    galleryImages[0],
    scene7extension || '',
    galleryContent?.imageBaseSrcPath,
    typeof scene7extension !== 'undefined',
  );
  newGalleryImages = [newGalleryImage, ...galleryImages.slice(1)]
  return newGalleryImages
}

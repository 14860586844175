import {styled, Breakpoints} from '@vw-marketing/us-components';

export const Controls: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${Breakpoints.b560}px) {
    position: absolute;
    padding-right: 180px;
    bottom: 25%;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    transition: opacity 500ms ease 500ms;
  }
`;

export const SlideInformation: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 100px 0 50px;

  @media (min-width: ${Breakpoints.b560}px) {
    color: #ffffff;
  }

  @media (max-width: ${Breakpoints.b960}px) {
    margin: 0 25px 0 50px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    margin: 30px 20px;
  }
`;

export const GalleryType: any = styled.div`
  display: none;
  @media (min-width: ${Breakpoints.b560}px) {
    display: block;
  }
`;

export const GalleryStatus: any = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 15px;
  }
`;

export const GalleryCounter: any = styled.div`
  @media (min-width: ${Breakpoints.b560}px) {
    display: flex;
    align-items: flex-end;
    margin-left: 25px;
  }
`;

export const GalleryCutline: any = styled.div`
  width: 100%;

  sup {
    margin-right: 4px;
  }
`;

export const GalleryDisclaimersContainer: any = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const BuildYours: any = styled.div`
  align-self: flex-start;
  margin-left: 20px;

  @media (min-width: ${Breakpoints.b560}px) {
    align-self: flex-end;
    min-width: fit-content;
    margin-left: 0;

    a {
      background: #ffffff;
      border: none;

      &:hover {
        background: #ffffff;
        border: none;
      }
      div {
        &:hover {
          color: #001e50;
        }
      }
    }
  }

  @media (min-width: 1441px) {
    a {
      border-radius: 25px;
    }
  }

  @media (max-width: ${Breakpoints.b560}px) {
    a {
      border-radius: 25px;
    }
  }
`;

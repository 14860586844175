import {GalleryAppState} from '.';
import { fetchInitialContentGalleryImage } from '../../fetch-data/fetch-gallery-image';
import {GalleryAppContent} from '../../typings/gallery';

export async function getGallerySectionsContent(
  galleryContent: GalleryAppContent,
): Promise<GalleryAppState> {
  const {
    leftSectionImages,
    leftSectionConfig,
    rightSectionImages,
    rightSectionConfig,
    scene7Config,
  } = galleryContent;
  let gallery: GalleryAppState = {status: 'loaded'};
  const scene7ConfigElement =
    scene7Config && scene7Config.length ? scene7Config[0] : undefined;
  if (
    leftSectionImages &&
    leftSectionImages?.length > 0 &&
    leftSectionConfig?.length
  ) {
    const leftImages = await fetchInitialContentGalleryImage(
      leftSectionImages,
      leftSectionConfig[0],
      scene7ConfigElement,
    );
    gallery = {
      ...gallery,
      leftContent: {...leftSectionConfig[0], images: leftImages, type: 'left'},
    };
  }
  if (
    rightSectionImages &&
    rightSectionImages?.length > 0 &&
    rightSectionConfig?.length
  ) {
    const leftImages = await fetchInitialContentGalleryImage(
      rightSectionImages,
      rightSectionConfig[0],
      scene7ConfigElement,
    );
    gallery = {
      ...gallery,
      rightContent: {
        ...rightSectionConfig[0],
        images: leftImages,
        type: 'right',
      },
    };
  }

  if (!gallery.leftContent && !gallery.rightContent)
    return {
      ...gallery,
      status: 'error',
    };
  return gallery;
}
